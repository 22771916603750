
/******************* Page body styling & sizing *******************/
body {
  background-color: rgb(236, 243, 235);
  padding: 0px;
}

@media (max-width: 768px){
  .container-fluid.px-sm-3{
    margin-bottom: 1.5rem;
  }

  .samlet-bio-icon{
    display: none;
  }

  .mtBio{
    margin-top: 10px !important;
  }
}

.content {
  background-color: rgb(236, 243, 235);
  display: flex;
  padding-top: 0px;
  flex-direction: column!important;
}

.menux{
  display: flex;
}

.footer{
  height: 605px;
  display: inline-block;

  width: 100% !important;
}

.ProviceLogo{
  height: 15px;
  margin: 0.3rem 0 0 0.5rem;

}

.navigation-menu.container-fluid{
  padding:0 !important;
}

@media (min-width: 768px){
  #navigator{
    display: inline-block;
  }

  .content{
    display: inline-block;
    width: calc(100% - 114.99px);
  }
}

@media (max-width: 768px){
  .table-section{
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .footer{
    height: 305px;
  }

  .BackgroundStyle{
    height: 40% !important;
  }

  .TextStyle{
    top: 90% !important;
    transform: 'translateX(-48.5%)' !important;
  }
}

/******************* Menu styles *******************/
.header {
  background-color: #3e8e35;
  padding: 0px 20px 20px 20px;
}

ul.navigator li {
  display: inline-block;
  list-style-type: none;
  margin: 0;
}

ul.navigator {
  background-color: rgb(255, 255, 255);
  padding: 0;
  width:fit-content;
}

ul.navigator li a {
  text-align: center;
  font-size: 13px;
  color: #090909;
  text-decoration: none;
}

.bar-icon{
  color: #3e8e35;
}

.menu-icon{
  border: 0;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  cursor: pointer;  
  background-color: #fff;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.menu-icon:hover{
  background-color: #ecf3eb;
}

@media screen and (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .menu-option{
    margin: 14px 0 0 14px !important;
  }

  .navigation-menu ul{
    display: none;
  }

  .navlink-item{
    width: auto !important;
    display: flex !important;

  }

  .navigation-menu.expanded{
    transition: top 3s ease !important;
  }

  .navigation-menu.expanded ul{
    display: block;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease-in;
  }

  .navigation-menu ul {
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    background-color: white;
  }
  .navigation-menu li {
    text-align: center;
  }

  .content.add{
    margin-top: 265px;
  }
}

@media (min-width: 768px){
  .side-page{
    padding: 20px 20px 70px 20px;
  }

  .navigation-menu{
    display: flex;
  }
}

.content li {
  margin-bottom: 10px;
}

.active {
  background-color: rgb(236, 243, 235);
}

.logofgo{
  width: 200px !important;
  text-align: left;
}

.navlink-item {
  display: block;
  width: 115px;
  padding: 20px 15px 20px 15px; 
  text-decoration: none;
  color: #000; 
}

.navlink-item:hover {
  background-color: rgb(236, 243, 235)
}

/******************* Typography settings *******************/
.right{
  text-align: right;
}

.center{
  text-align: center;
}

.bold-text{
  font-weight: bold;
}

h4.title{
  color: rgb(255, 255, 255);
  text-align: right;
}

h2.title, p.title{
  margin-top: 50px;
}

p.sub-title{
  font-size: 15px !important;
}

h1.title{
  margin-top: 25px;
}

h1{
  letter-spacing: 8px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Georgia,Times,Times New Roman,serif;
}

h2, h3, h4, h5, h6 {
  letter-spacing: 4px; 
}

.title-spacing{
  letter-spacing: 0px;
}

.style1{
  cursor: default !important;
  user-select: none !important; 
  font-size: 16px !important;
  color: #757575 !important;
  margin-bottom: 0px;
}

.style2{
  cursor: default !important;
  user-select: none !important; 
  font-size: 14px !important;
  color: #BDBDBD !important;
}

.style3{
  font-size: 14px !important;
  color: #696969;
}

@media(min-width: 800px){
  .mb3{
    margin-bottom: 0.7rem;
  }

  .mt3{
    margin-top: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .h1, h1 {
    font-size: 2.2rem !important;
  }
  .h3, h3{
    font-size: 1.3rem !important;
  }
}

.vejl-text{
  font-size: 20px
}

/******************* Image settings *******************/

.transport_image{
  width: 110px;
  text-align: right;
}

.question-icn{
  margin-left: 10px !important;
}

.samlet-bio-icon{
  position: absolute;
  margin-left: 1rem;
}

/******************* Dropdown styles *******************/
.form-select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 320 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z'/%3E%3C/svg%3E") !important;
  background-size: 18px 18px !important;
}

.dropdown-section{
  background-color: white;
  padding : 30px 0px 30px 0px;
  border-radius: 7px;
}

.dropdown-text{
  text-align: left;
  font-size: 13px;
}

.dropdown-menu{
  width: 200px !important;
}

.hide-dropdown{
  display: none;
}

.custom-select {
  background-color: white !important;
  border: 1px solid black !important;
  width: 265px !important;
  padding: 10px 20px 10px 20px !important;
}

@media (min-width: 1400px){
  .pdr{
    padding-right: 0 !important;
  }
  
  .pdl{
    padding-left: 0 !important;
  }
}

option:disabled {
  color:  rgb(197, 197, 197) !important;
}

/******************* Chart styles *******************/

.graph-section{
  background-color: white;
  padding : 30px;
  border-radius: 7px;
}

@media (min-width: 1300px){
  .flex-set{
    display: flex !important;
  }
}

.graph-section-with-info{
  background-color: white;
  padding : 15px 30px 30px 30px;
  border-radius: 7px;
}

#piechart{
  height: 100%;
}

@media (min-width: 650px){
  .stacked-pd{
    padding: 0 6rem 0 6rem;
  }

  .pie-width{
    padding-bottom: 53px;
  }
}

.left-section{
  margin: 20px 0px 0px 0px;
}

.right-section{
  margin: 20px 0px 0px 0px;
}

@media (min-width: 768px){
  .stacked-width{
    width: 130px !important;
  }
}


/******************* Table styles *******************/

.table-head{
  border-bottom-width: 0;
  border-style: none;
}

.table-section{
  width: 100%;
  background-color: white;
  padding : 30px;
  border-radius: 7px;
}

@media (min-width:768px){
  .table-right-align{
    padding-right: 2rem !important;
  }
}

@media (min-width:768px){
  .bio-col-val{
    margin-right: 2.5rem;
  }
}

td.table-right-align{
  padding-left: 0 !important;
}

td.num.right{
  padding-right: 0 !important;
}

.klima-table tr{
  border-color: transparent !important;
}

.table-default{
  border-color: white;
  border-bottom-color: black;
  border-bottom-width: 2px !important;
}

.table-default-double{
  border-color: white;
  border-bottom-color: black;
  outline-offset: 10px;
  border-bottom-style: double;
  border-bottom-width: 6px !important;
}

.green-row{
  background-color: #edf4ea !important;
  border-color: transparent !important;
}

.total-row{
  border-top-color: black ;
  border-top-width: 2px !important;
}

.table-border{
  border-color: transparent !important;
}

.transparent{
  color: transparent;
}

.mc-graph{
  position: relative !important; 
  height:40vh !important; 
  width:80vw !important;
}

.mb-energi{
  margin-bottom: 1.7rem !important;
}

@media (max-width: 768px){
  .small-table-p{
    font-size: 13px;
  }

  .small-table-h{
    font-size: 0.8rem;
  }
}

/******************* Slider styles *******************/

.square {
  height: 238px;
  width: 100%;
  background-image: linear-gradient(to right, #969696, #579c43);
  border-radius: 15px;
  background-color: #555;
}

.rc-slider-handle{
  background: url('./Biofaktor-ikon.png') no-repeat center !important;
  border: solid 0px transparent;
  cursor: default;

}

.rc-slider-mark{
  top: 244px;
  bottom: 20px;
  font-size: 1.4rem;
  font-family: Georgia,Times,Times New Roman,serif;
}

.rc-slider-mark-text{
  cursor: default;
  color: black;
}

.bio-slider-div{
  padding-bottom: 50px !important;
}
.rc-slider-handle:hover {
  border-color: transparent !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging{
  border-color: transparent !important;
  box-shadow: none !important;
}

.rc-slider-handle:active {
  cursor: auto !important;
}

.rc-slider-handle{
  opacity: initial;
}

.rc-slider-rail {
  background-color: transparent !important;
}
.rc-slider-dot-active {
  border-color: transparent;
}

.rc-slider-dot {
    background-color: transparent;
    border: 0px solid transparent;
    cursor: auto;
}

.css-9l5vo-MuiCollapse-wrapperInner {
  position: absolute;
  width: 14% !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  text-transform: none !important;
  color: rgb(128 128 128) !important;
  font-size: 1rem !important;
}

@media (min-width: 1400px){
  #main-chart
  {
    margin-left: 8rem !important;
  }

}

.css-ki1hdl-MuiAlert-action{
  margin-left: 0 !important;
}

.css-1gv0vcd-MuiSlider-track, .css-14pt78w-MuiSlider-rail{
  color: transparent !important;
}

.MuiSlider-thumb{
  color: #969696;
}

/*********************Info Box Styles***********************/

@media (max-width: 768px){
  .prInfo{
    padding-right: 0px !important;
  }
}